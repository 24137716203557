import { configurationMethods } from "../../state/helpers/configuration"

export const companyInfoMixin = {
  data() {
    return {
      company_info: {},
      comapany_name: "",
      is_dataFetch: true,
      hasMounted: false, // Add this flag
    };
  },
  created() {
    if (this.is_dataFetch && !this.hasMounted) {
      this.getInfo();
      this.hasMounted = true; // Set the flag to true after the first mount
    }
  },
  methods: {
    ...configurationMethods,
    getInfo() {
      const id = 1;
      this.showConfiguration(id).then((response) => {
        const data = response.data.data;
        this.company_info = JSON.parse(data);
        this.is_dataFetch = false;

        const inputSentence = JSON.parse(data).company_name;
        const targetWord = "Sound";

        const modifiedSentence = inputSentence?.replace(targetWord, `${targetWord}<br/>`);
        this.comapany_name = modifiedSentence;
      });
    },
  },
};