import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'

// set your computed methods by mapping the state with data and setting our getters to call api;
export const configurationComputed = {
    ...mapState('configuration', {
        configurations:(state) => state.configurations,
        configuration:(state) => state.configuration,
    }),
    ...mapGetters('configuration', ['getConfiguration', 'showConfigurationData'])
}

// export property actions
export const configurationMethods = mapActions('configuration', ['allConfiguration','createConfiguration',
 'updateConfiguration', 'deleteConfiguration','showConfiguration',
])

export const configurationMutations = mapMutations('configuration', ['SHOW_CONFIGURATION']);
